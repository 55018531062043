import { isRouteErrorResponse, useParams, useRouteError } from '@remix-run/react';
import { type ErrorResponse } from '@remix-run/router';
import { getErrorMessage } from '#app/utils/misc.tsx';

type StatusHandler = (info: { error: ErrorResponse; params: Record<string, string | undefined> }) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  const params = useParams();

  if (typeof document !== 'undefined') {
    console.error(error);
  }

  return (
    <main id="main" className="error-main">
      <article className="error-page">
        <header>
          <h1> Women Of the World</h1>
        </header>

        <div className="error-content">
          <p>Sorry, something didn't go right...</p>

          {isRouteErrorResponse(error)
            ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
                error,
                params
              })
            : unexpectedErrorHandler(error)}
        </div>

        <footer className="coming-soon-footer">
          <div className="coming-soon-footer-bottom">
            <div className="coming-soon-footer-bottom-inner">
              <p>
                {/* eslint-disable-next-line remix-react-routes/use-link-for-routes */}
                &copy; Pierre Maraval {new Date().getFullYear()} · <a href="mailto:info@wow.earth">info@wow.earth</a>
              </p>
            </div>
          </div>
        </footer>
      </article>
    </main>
  );
}
